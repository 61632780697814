<template>
  <div class="col-md-12 quotation-line">
    <div class="row">

      <div class="col-md-1 prod-img">
        <template v-if="quotation.customProduct">          
          <span class="cust-prod-icon">
            <i class="fa fa-wrench"></i>
          </span>
        </template>
        <template>
          <img class="img-responsive" :src="quotation.imageUrl" />
        </template>
      </div>

      <div class="col-md-6 prod-desc label-bold">        
        <template v-if="quotation.customProduct">  
          <input type="text" v-model="quotation.productDescription" @change="updateProductDescription()" />
        </template>
        <template v-else>  
          <p class="label-bold">{{ quotation.productDescription }}</p>
          <p class="comment">{{ quotation.comment }}</p>
          <p class="text-small text-grey-100">{{ quotation.productId }}</p>
        </template>
      </div>

      <div class="col-md-2 prod-price">        
        <utlz-decimal-field :initialValue="quotation.productPrice" @valueUpdatedEvent="updatePrice($event)"></utlz-decimal-field>
      </div>

      <div class="col-md-1 prod-qty">        
        <input v-model="productQuantity" class="form-control" @change="updateQuantity()">
      </div>

      <div class="col-md-1 row-amt">
        {{ formattedRowAmount }}
      </div>

      <div class="col-md-1 delete">
        <i class="fa fa-trash-o delete-button" @click="deleteQuotation()"></i>
      </div>

    </div>
  </div>
</template>

<script>
import { formatPrice } from 'src/utilities/helpers';
import DecimalField from './../../bespoke/quotations/DecimalField.vue';
export default {
  props: {
    quotation: { type: Object, required: true }
  },
  components: {
    'utlz-decimal-field': DecimalField
  },
  data() {
    return {      
      productQuantity: this.quotation.quantity
    }
  },
  computed: {
    formattedRowAmount() {
      return formatPrice(this.quotation.rowAmount);
    }
  },
  methods: {
    deleteQuotation() {
      this.$emit('deleteQuotationEvent', this.quotation.recId);
    },
    updatePrice(productPrice) {
      var rowAmount = productPrice * this.productQuantity;
      var payload = { price: productPrice, rowAmount: rowAmount, recId: this.quotation.recId };
      this.$emit('updatePriceEvent', payload);
    },
    updateQuantity() {
      var parsedQuantity = parseInt(this.productQuantity);

      if (parsedQuantity < 0 || isNaN(parsedQuantity)) {
        this.productQuantity = 0;
        return;
      }

      this.productQuantity = parsedQuantity;
      var rowAmount = this.quotation.productPrice * parsedQuantity;
      var payload = { quantity: parsedQuantity, rowAmount: rowAmount, recId: this.quotation.recId };
      this.$emit('updateQuantityEvent', payload);
    },
    updateProductDescription() {
      var payload = { prodDesc: this.quotation.productDescription, recId: this.quotation.recId };
      this.$emit('updateProductDescriptionEvent', payload);
    }
  }
}
</script>

<style>

</style>