<template>
  <div class="cmp-order-product normalize-headers" :class="parentClassRef">
    
    <!-- BESPOKE DEPG -->
    <utlz-calculation-box :quantity="quantity" :product="product" v-if="useCalculationBox && product.prices.length" @changedCalculations="changedCalculations($event)"></utlz-calculation-box>
    <utlz-canopy-calculator :quantity="quantity" :product="product" v-if="useCanopyCalculator && product.prices.length" @changedCanopyCalculations="changedCanopyCalculations($event)"></utlz-canopy-calculator>
    <!-- END BESPOKE DEPG -->

    <utlz-order-normal
      v-if="orderType === 'normal'"
      @orderNormal="orderProductNormal($event)"
    ></utlz-order-normal>

    <utlz-order-saleunit
      v-if="orderType === 'saleUnit'"
      :saleUnit="product.saleUnit"
    ></utlz-order-saleunit>

    <utlz-order-units
      v-if="orderType === 'units' && product.stock"
      :units="product.units"
      :stockTotal="product.stock.stockTotal"
      :selectedUnitCode="selectedUnitCode"
      @unitsChanged="unitsChanged($event)"
    ></utlz-order-units>

    <utlz-order-grouped v-if="orderType === 'grouped'" :product="product"></utlz-order-grouped>

    <utlz-order-volume-discount
      v-if="orderType === 'volumeDiscount'"
      :volumeDiscount="product.prices"
      @volumeDiscountChanged="volumeDiscountChanged($event)">
    </utlz-order-volume-discount>

    <!-- BESPOKE DEPG-31: We use order comment in the framework, but only because we 
        need to send the value from utlz-calculation-box as an order comment, so disable default order comment -->
     <input v-if="useOrderComment" type="text" :value="orderCommentField" ref="orderComment" class="order-comment" :placeholder="commentPh"> 
    <!-- END BESPOKE DEPG-31 -->

    <!-- BESPOKE DEPG-31: Added opion to show clickThrough button instead of the default order product -->
    <template v-if="useClickThroughButton">
      <a
        v-if="useClickThroughButton"
        :href="product.targetUrl"          
        class="button add-to-cart-btn small">
        <i class="uws-icon uws-cart" aria-hidden="true"></i>
        <span v-translation="{ type: 'button', code: 'button_order'}"></span>
      </a>
      <template v-if="showQuotationButton">
        <a class="add-to-quote-btn" @click="addProductToQuotationEvent(product.id)">
          <i class="fa fa-file-text-o"></i>
          <template v-if="useQuoteButtonText">
            <span v-translation="{ code: 'button_addto_quotes', type: 'button' }"></span>
          </template>
        </a>
      </template>
    </template>

    <template v-else>
      <!-- BESPOKE DEPG-31: Added extra class calc-box if calculationbox or canopy calculator are being used -->
      <div class="order-product-wrapper flex-row-nowrap" :class="{ 
        'disabled': stockLimit && !isValidQuantity, 
        'calc-box': (useCalculationBox || useCanopyCalculator) && product.prices.length 
        }">

        <!-- END BESPOKE DEPG-31 -->
        <utlz-quantity-buttons
          :initialQuantity="quantity"
          :allowZeroQuantity="true"
          :quantityFactor="quantityFactor"
          @quantityChanged="quantityChanged($event)">
        </utlz-quantity-buttons>

        <!-- BESPOKE: DEPA: Added text button option -->
          <a v-if="useOrderMode && !useTextButton" @click="orderProduct" class="button add-to-cart-btn small">
            <i class="uws-icon uws-cart" aria-hidden="true"></i>
          </a>
          <a
            v-if="useOrderMode && useTextButton"
            @click="orderProduct"          
            class="inputbutton btn-u btn-u-sea-shop btn-u-lg text-order-btn">
            <i class="uws-icon uws-cart" aria-hidden="true"></i>
            <span v-translation="{ type: 'button', code: 'button_order'}"></span>
          </a>
        <!-- END BESPOKE DEPA -->

        <!-- BESPOKE DEPA: Add button to add product to quotationlist -->
        <template v-if="showQuotationButton">
          <a class="add-to-quote-btn" @click="addProductToQuotationEvent(product.id)">
            <i class="fa fa-file-text-o"></i>
            <template v-if="useQuoteButtonText">
              <span v-translation="{ code: 'button_addto_quotes', type: 'button' }"></span>
            </template>
          </a>
        </template>
        <!-- END BESPOKE DEPA -->

        <!-- Displays stocklimit exceeded tooltip -->
        <transition name="fade">
          <template v-if="stockLimit && !isValidQuantity">
            <div class="utlz-tooltip stock-limit danger">
              <p v-translation="{ type: 'label', code: 'label_exceeds_stock_limit' }"></p>
            </div>
          </template>
        </transition>

      </div>
    </template>
    <!-- END BESPOKE DEPG-31 -->

  </div>
</template>

<script>
import OrderNormal from 'src/components/webshop/order-product/blocks/OrderNormal.vue';
import OrderSaleUnit from 'src/components/webshop/order-product/blocks/OrderSaleUnit.vue';
import OrderUnits from 'src/components/webshop/order-product/blocks/OrderUnits.vue';
import OrderGrouped from 'src/components/webshop/order-product/blocks/OrderGrouped.vue';
import OrderVolumeDiscount from 'src/components/webshop/order-product/blocks/OrderVolumeDiscount.vue';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
// BESPOKE DEPG
import CalculationBox from './../../../bespoke-components/CalculationBox.vue';
import CanopyCalculator from './../../../bespoke-components/CanopyCalculator.vue';
// END BESPOKE DEPG
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'utlz-order-normal': OrderNormal,
    'utlz-order-saleunit': OrderSaleUnit,
    'utlz-order-units': OrderUnits,
    'utlz-order-grouped': OrderGrouped,
    'utlz-order-volume-discount': OrderVolumeDiscount,
    'utlz-quantity-buttons': QuantityButtons,
    // BESPOKE DEPG
    'utlz-calculation-box': CalculationBox,
    'utlz-canopy-calculator': CanopyCalculator
    // END BESPOKE DEPG
  },
  props: {
    product: { type: Object, required: true },
    initialQuantity: { type: Number, required: false, default: 1 },
    initialUnitCode: { type: String, required: false },
    useOrderComment: { type: Boolean, required: false, default: false },
    orderComment: { type: String, required: false },
    useOrderMode: { type: Boolean, default: true },
    totalOrderLinesQuantity: { type: Number, required: false, default: null },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE DEPA
    useTextButton: { type: Boolean, required: false, default: false },
    useClickThroughButton: { type: Boolean, required: false, default: false },
    useCalculationBox: { type: Boolean, required: false, default: false },
    useCanopyCalculator: { type: Boolean, required: false, default: false },
    showQuotationButton: { type: Boolean, required: false, default: true },
    useQuoteButtonText: { type: Boolean, required: false, default: false }
    // END BESPOKE DEPA
  },
  data () {
    return {
      quantity: this.initialQuantity,
      quantityFactor: 1,
      orderType: 'normal',
      selectedUnitCode: '',
      // BESPOKE
      calculationsMsg: '',
      totalPrice: 0,
      commentPh: window.vue.translations.label.label_orderline_comment_ph
      // END BESPOKE
    };
  },
  computed: {
    ...mapGetters(['stockLimit', 'showStock']),
    ...mapGetters('quotations', ['selectedQuote']),
    orderCommentField () {
      return this.orderComment;
    },
    isValidQuantity () {
      if (this.stockLimit) {
        if (this.useOrderMode || this.totalOrderLinesQuantity === null) {
          return this.quantity <= this.product.stock.stockTotal;
        } else {
          return this.totalOrderLinesQuantity <= this.product.stock.stockTotal;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    product () {
      this.setOrderType();
      this.setOrderQuantities();
    }
  },
  methods: {
    // BESPOKE DEPA
    ...mapActions('quotations', ['addProductToQuotation', 'showSelectQuotesTool', 'promptUserForQuote']),
    addProductToQuotationEvent() {
      var quoteProduct = { 
        productId: this.product.id,
        productDescription: this.product.shortDescription,
        quantity: this.getComputedQuantity(this.quantity),
        productPrice: this.product.prices[0].rawPrice,
        comment: '',
        totalPrice: ''
      };

      // If it is a custom glass product, add the measurements in the comments and the total price
      if (this.useCalculationBox || this.useCanopyCalculator) {
        quoteProduct.comment = this.calculationsMsg;
        quoteProduct.productPrice = this.totalPrice / quoteProduct.quantity;
      }

      if (!this.selectedQuote) {
        this.promptUserForQuote(quoteProduct);
      } else {
        this.addProductToQuotation(quoteProduct);
      }
    },
    changedCalculations ({ calcValues, totalPrice }) {
      this.calculationsMsg = calcValues;
      this.totalPrice = totalPrice;
    },
    changedCanopyCalculations({ calcValues, totalPrice }) {
      this.calculationsMsg = calcValues;
      this.totalPrice = totalPrice;
    },
    // END BESPOKE DEPA
    setOrderType () {
      let type = 'normal';
      if (this.product.units) {
        type = 'units';
      } else if (this.product.saleUnit > 1) {
        type = 'saleUnit';
      }
      this.orderType = type;
    },
    setOrderQuantities () {
      switch (this.orderType) {
        case 'normal':
          this.quantityFactor = 1;
          this.quantity = this.initialQuantity > this.quantityFactor || this.allowZeroQuantity ? 0 : this.quantityFactor;
          break;
        case 'saleUnit':
          const initialQuantity = this.initialQuantity * this.product.saleUnit;
          this.quantityFactor = this.product.saleUnit;
          this.quantity = initialQuantity > this.quantityFactor || this.allowZeroQuantity ? 0 : this.quantityFactor;
          break;
        case 'volumeDiscount':
          this.quantityFactor = this.product.prices[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor || this.allowZeroQuantity ? 0 : this.quantityFactor;
          break;
        case 'units':
          let index = 0;
          if (this.initialUnitCode) {
            this.product.units.filter((unit, i) => {
              if (unit.code === this.initialUnitCode) {
                index = i;
              }
            });
          }
          this.selectedUnitCode = this.product.units[index].code;
          this.quantityFactor = this.product.units[index].quantity;
          this.quantity = this.initialQuantity > 1 || this.allowZeroQuantity ? 0 * this.quantityFactor : this.quantityFactor;
          break;
      }
    },
    orderProduct () {
      let payload = this.getOrderPayload();
      this.addToCart(payload)
        .then(res => {
          this.quantity = this.quantityFactor;
        });
    },
    getOrderPayload () {
      // BESPOKE DEPG-31
      // If we use the calculationsbox, we need to send the calculation box values as an order comment
      let payload = {
        prod_code: this.product.id,
        ord_qty: this.getComputedQuantity(this.quantity),
        unit_code: this.selectedUnitCode,
        prod_comment: this.useOrderComment ? this.$refs.orderComment.value : ''
      };

      if (this.useCalculationBox || this.useCanopyCalculator) {
        payload.prod_comment = this.calculationsMsg;
        payload.total_price = this.totalPrice
      }
      return payload;
      // END BESPOKE DEPG-31      
    },
    getComputedQuantity (quantity) {
      let computedQuantity = quantity;
      if (this.orderType === 'units') {
        computedQuantity = this.quantity / this.quantityFactor;
      } else if (this.orderType === 'volumeDiscount' && this.product.saleUnit > 1) {
        computedQuantity = this.quantity / this.product.saleUnit;
      } else if (this.orderType === 'saleUnit') {
        computedQuantity = this.quantity / this.product.saleUnit;
      }
      return computedQuantity;
    },
    quantityChanged ({ quantity }) {
      this.quantity = quantity;
      if (!this.useOrderMode) {
        this.$emit('quantityChanged', {
          id: this.product.id,
          unitCode: this.selectedUnitCode,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      }
    },
    unitsChanged ({ quantity, unitCode }) {
      this.selectedUnitCode = unitCode;
      this.quantity = this.allowZeroQuantity ? 0 : quantity;
      this.quantityFactor = quantity;
      if (!this.useOrderMode) {
        this.$emit('unitsChanged', {
          unitCode: unitCode,
          quantity: quantity,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      }
    },
    volumeDiscountChanged (quantity) {
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    ...mapActions('shoppingCart', ['addToCart'])
  },
  created () {
    this.setOrderType();
    this.setOrderQuantities();
  }
};
</script>

<style>
</style>
