<template>
  <div class="cmp-calculation-box canopy-calculator utlz-col-sm-12">
    <div class="box-wrapper">
      <div class="column-label">

        <div class="col-row pane-height">
          <div class="cell pane-label">            
            <span v-translation="{ type: 'label', code: 'label_canopy_width' }"></span>            
          </div>
          <div class="cell pane-value">
            <div class="input-wrapper">
              <input class="pane-height" type="number" @keydown.enter="changeCanopyWidth" @change="changeCanopyWidth" v-model="canopyWidth">
              <span class="unit-mm">{{ units }}</span>
            </div>
            <span class="range-values">({{minWidth + units }} - {{ maxWidth + units }})</span>
          </div>
        </div>

        <div class="col-row panels">
          <div class="cell pane-label">
            <span v-translation="{ type: 'label', code: 'label_canopy_planes' }"></span>
          </div>
          <div class="cell pane-value">        
            <div class="input-wrapper">    
              <input class="total-panels" type="number" @keydown.enter="changeNumberOfPanels" @change="changeNumberOfPanels" v-model="numberOfPanels">
            </div>
            <span class="range-values">({{ minPanels }} - {{ maxPanels }})</span>
          </div>
        </div>

        <div class="col-row canopy-depth">
          <div class="cell pane-label">
            <span v-translation="{ type: 'label', code: 'label_canopy_depth' }"></span>
          </div>
          <div class="cell pane-value">        
            <div class="input-wrapper">    
              <input class="depth" type="number" @keydown.enter="changeCanopyDepth" @change="changeCanopyDepth" v-model="canopyDepth">
              <span class="unit-mm">{{ units }}</span>
            </div>
            <span class="range-values">({{ minDepth + units }} - {{ maxDepth + units }})</span>
          </div>
        </div>

        <div class="col-row panel-totals">
          <template v-if="showPrices && product.prices.length">
            <div class="col-row">
              <div class="cell pane-label">
                <span v-translation="{ type: 'label', code: 'total_price' }"></span>
              </div>
              <div class="cell pane-value">
                <span>{{ totalFormattedPrice }}</span>
              </div>
            </div>
          </template>
        </div>
        </div>        
      </div>
     
    </div>
</template>

<script>
import { formatPrice } from 'src/utilities/helpers';
import { mapGetters } from 'vuex';
export default {
  props: {
    product: { type: Object, required: true },
    quantity: { type: Number, required: false, default: 1 }
  },
  data () {
    return {
      minWidth: 1,
      maxWidth: 10000, //???
      minPanels: 0,
      maxPanels: 0, //????
      minPanelWidth: 300, //????
      maxPanelWidth: 780, // Max panel width is 750, but take 30 extra into account for the edge overlay
      minDepth: 1, //????
      maxDepth: 3000, //????

      canopyWidth: 5000,
      numberOfPanels: 1,
      canopyDepth: 100, //????

      units: 'mm',
      labelCanopyWidth: window.vue.translations.label.label_canopy_width,
      labelCanopyPlanes: window.vue.translations.label.label_canopy_planes,
      labelCanopyDepth: window.vue.translations.label.label_canopy_depth,
      labelCanopyGlassLength: window.vue.translations.label.labelCanopyGlassLength,
      labelCanopyGlassWidth: window.vue.translations.label.labelCanopyGlassWidth,
      labelNumberOfGlassPanels: window.vue.translations.label.labelNumberOfGlassPanels
    };
  },
  watch: {
    quantity (val) {
      this.$emit('changedCanopyCalculations', { calcValues: this.calculationValues, totalPrice: this.totalPrice.toFixed(2) });
    }
  },
  computed: {
    ...mapGetters(['showPrices', 'bespokeGlobalWs']),
    defaultPrice () {
      return this.product.prices.length ? this.product.prices[0].rawPrice : 0;
    },
    calculationValues () {
      // Add 20mm to the depth as required for the extra overlap
      var labelCanopyGlassLength = `${this.labelCanopyGlassLength}: ${(this.canopyDepth + 20)}${this.units}`;
      var glasPanelWidth = (this.canopyWidth / this.numberOfPanels).toFixed(2);
      var labelCanopyGlassWidth = `${this.labelCanopyGlassWidth}: ${glasPanelWidth}${this.units}`;
      var labelNumberOfGlassPanels = `${this.labelNumberOfGlassPanels}: ${this.numberOfPanels}`;

      if (this.bespokeGlobalWs.shopName.toLowerCase() == 'de') {
        //paneldimensionsLabel = 'Netto-Glasmaß: ' //?????
        //panelWidthLabel = 'Paneelbreite: '; // ????????
      }

      return `${labelCanopyGlassLength}, ${labelCanopyGlassWidth}, ${labelNumberOfGlassPanels}`;
    },
    totalPrice () {
      // TODO
      // HOW TO CALCULATE TOTAL PRICE?
      // let totalPrice = (((this.paneHeight * this.numberOfPanels) / 1000000) * this.defaultPrice) * this.quantity;
      // let customGlassPrice = this.quantity * this.product.customDecimals.CUSTOM_GLASS_PRICE;
      // return totalPrice + customGlassPrice;
      return 12345.00
    },
    totalFormattedPrice () {
      return formatPrice(this.totalPrice);
    }
  },
  methods: {
    changeCanopyWidth () {
      this.canopyWidth = this.processValue(this.canopyWidth, this.minWidth, this.maxWidth);
      
      // After changing the width, adjust the number of panels accordingly
      this.setNumberOfGlassPanelRestrictions();

      // Check if the current number of panels is still in range, and if 
      // not adjust it to the nearest min or max number of allowed panels
      if (this.numberOfPanels < this.minPanels) {
        this.numberOfPanels = this.minPanels;
      } else if (this.numberOfPanels > this.maxPanels) {
        this.numberOfPanels = this.maxPanels;
      }

      this.emitValues();
    },
    changeNumberOfPanels () {
      this.numberOfPanels = this.processValue(this.numberOfPanels, this.minPanels, this.maxPanels);
      this.emitValues();
    },
    changeCanopyDepth () {
      this.canopyDepth = this.processValue(this.canopyDepth, this.minDepth, this.maxDepth);
      this.emitValues();
    },
    setNumberOfGlassPanelRestrictions() {
      var canopyWidth = this.canopyWidth - 60; // Width minus 60 for the side girders
      this.minPanels = Math.ceil(canopyWidth / this.maxPanelWidth);
      this.maxPanels = Math.ceil(canopyWidth / this.minPanelWidth);
    },
    emitValues() {
      console.log(this.totalPrice);
      this.$emit('changedCanopyCalculations', { calcValues: this.calculationValues, totalPrice: this.totalPrice.toFixed(2) });
    },
    processValue (value, min, max) {
      const val = Math.floor(parseInt(value));
      if (val < min) { return min; }
      else if (val > max) { return max; }
      else { return val };
    }
  },
  created () {
    // Initialize the min and max number of allowed panels, based on the default value of the canopy width
    this.setNumberOfGlassPanelRestrictions();

    // Set the default number of panels for the initialized values
    this.numberOfPanels = Math.ceil(this.canopyWidth / this.maxPanelWidth);
    this.emitValues();
  }
};
</script>

<style>
</style>
